document.addEventListener('DOMContentLoaded', function () {
  // Selektujte select polje za broj dece
  var childrenSelect = document.getElementById('children');
  // Selektujte div u koji ćete dodavati polja za unos godina
  var ageFieldsContainer = document.querySelector('.age-fields-container');

  // Funkcija za dodavanje polja za unos godina
  function addAgeFields(num) {
    ageFieldsContainer.innerHTML = ''; // Obrišite postojeća polja

    for (var i = 1; i <= num; i++) {
      var ageField = document.createElement('div');
      ageField.className = 'col-lg-6 age-field';
      ageField.innerHTML = '<input type="number" name="child_age_' + i + '" class="form-control" placeholder="Child ' + i + ' Age">';
      ageFieldsContainer.appendChild(ageField);
    }
  } 

  // Postavite inicijalni broj polja za unos godina na osnovu trenutnog izbora
  addAgeFields(childrenSelect.value);

  // Osvežite polja za unos godina kada se izmeni izbor u select polju
  childrenSelect.addEventListener('change', function () {
    addAgeFields(this.value);
  });
});

(function ($) {
  $(function () {

  


    /*
     * Navbar fixed 
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top');
      } else {
        $('.site-header').removeClass('navbar-fixed-top');
      }
    });

    // $('#primary-menu-mobie a').bind('click.smoothscroll', function (e) {
    //   e.preventDefault();
    //   var target = this.hash,
    //     $target = $(target);

    //   $('html, body').stop().animate({
    //     'scrollTop': $target.offset().top - 200
    //   }, 900, 'swing', function () {
    //     window.location.hash = target;
    //   });
    // });

    $('#primary-menu-mobie a').on('click', function (e) {
      // e.preventDefault();

      var target = this.hash;
      var $target = $(target);

      console.log($target);

      if ($target.length) {
        $('html, body').animate({
          'scrollTop': $target.offset().top - 80
        }, 1000, 'swing');
      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.nav-icon').on('click', function () {
      $(this).toggleClass('icon-menu').toggleClass('icon-close');
      $('body').toggleClass('active-menu');
      $(".menu-mobile").toggleClass('sliding-open');

    });

    window.addEventListener('mouseup', function (event) {
      var box = document.querySelector('.nav-icon');
      var menu = document.querySelector('.menu-mobile');

      if (event.target != box) {
        menu.classList.remove('sliding-open');
        box.classList.remove('icon-close');
        box.classList.add('icon-menu');
      }
    })

    $('#form .start_date input, #form .end_date input, .wpcf7-form .start_date input, .wpcf7-form .end_date input').datepicker({});

    $('.chalets-slider').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
    });

    $('.resort-slider-1').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
    });

    $('.resort-slider-2').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
    });


    // $('.experience-slider-images').slick({
    //   infinite: true,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   arrows: false,
    //   dots: true,
    // });

    /*
     * Video modal 
     */
    $("body").on("click", "#play-video-modal", function (e) {
      e.preventDefault();
      $("#video-modal").modal("show");
      $("#video-modal iframe")[0].src += "?autoplay=1";
    });

    $("#video-modal").on('hidden.bs.modal', function (e) {
      var videoUrl = $("#video-modal iframe")[0].src;
      videoUrl = videoUrl.replace("?autoplay=1", "");
      $("#video-modal iframe")[0].src = videoUrl;
    });

    /*
     * Open modal
     */

    $('#open-modal').on('click', function (e) {
      e.preventDefault();
      $('#modal').modal('show');
    });

    /*
     * Youtube lazy loading
     */
    $('#play-video-btn').on('click', function (e) {
      e.preventDefault();
      $('#youtubeModal').modal('show');
    });

    $("#youtubeModal").on('hidden.bs.modal', function (e) {
      $("#youtubeModal iframe").attr("src", $("#youtubeModal iframe").attr("src"));
    });



    $('#play-video-btn').on('click', function () {
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
    })

    window.onYouTubeIframeAPIReady = function () {
      var videoModules = document.querySelectorAll('.video');
      videoModules = Array.prototype.slice.call(videoModules);
      videoModules.forEach(initializeVideoModule);
    }

    function initializeVideoModule(videoModule) {
      var player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          onStateChange: function (event) {
            var isEnded = event.data === YT.PlayerState.ENDED;
            videoModule.classList[isEnded ? 'remove' : 'add']('playing');
            if (isEnded) {
              player.destroy();
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModule(videoModule);
            }
          }
        }
      });
    }

    /*
     * Scroll up button
     */
    $(window).scroll(function () {
      if ($(this).scrollTop() > 150) {
        $('.scroll-to-top').addClass('show');
      } else {
        $('.scroll-to-top').removeClass('show');
        $('.scroll-to-top').addClass('animated bounceOutDown');
        $('.scroll-to-top').removeClass('animated bounceOutUp');
        $('.scroll-to-top i').css('transform', 'rotateY(0) scale(1)');
      }
    });

    $('.scroll-to-top').on("click", function () {
      $('.scroll-to-top').addClass('animated bounceOutUp');
      $('.scroll-to-top i').css('transform', 'rotateY(180deg) scale(1.2)');
      $("html, body").animate({
        scrollTop: 0
      }, 1000);
      return false;
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });

    AOS.init();


  });
})(jQuery);